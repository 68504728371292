import Styled from 'styled-components';
const PADDING_TOP_CONTENT = 10; // same as DesignHomeOffice component. Must match

export const FinancingContainer = Styled.div`
  width: 100%;
  padding: 1em;
  position: relative;
  z-index: 1;
  padding-top: ${PADDING_TOP_CONTENT / 2}rem;

  &:after {

    content: "";
    position: absolute;
    z-index: -1;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: #F1F0EF;

    @media only screen and (max-width: 768px) {
      height: 100%;
    }
  }

  button {
    border-radius: 0px;
    border: 2px solid #762738;
    margin: auto;
    span {
      color: #762738;
    }
  }
`;
export const Container = Styled.div`
  width: 100%;
  padding: 1em;
  position: relative;
  z-index: 1;
  padding-top: ${PADDING_TOP_CONTENT / 2}rem;

  &:after {

    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: #F1F0EF;

    @media only screen and (max-width: 768px) {
      height: 0%;
    }
  }

  button {
    border-radius: 0px;
    border: 2px solid #762738;
    margin: auto;
    span {
      color: #762738;
    }
  }
`;

export const IframeContainer = Styled.div`
  width: 100%;
  position: relative;
  min-height: 400px;
  aspect-ratio: 3/2;
`;

export const ImageContainer = Styled(IframeContainer)`
min-height: 300px;
margin-bottom: -30px;

@media only screen and (max-width: 768px) {
  margin-bottom: -25px;
  aspect-ratio: 3/2;
}
@media only screen and (max-width: 400px) {
  margin-bottom: -50px;
  aspect-ratio: 3/2;
}
`;

export const VirtualDescription = Styled.div`
  width: 90%;
  padding: 0;
  padding-top: ${PADDING_TOP_CONTENT / 2}rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem 1rem;
  }

`;

export const FinanceDescription = Styled.div`
  width: 100%;
  padding: 0;
  padding-left: 4rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem 1rem;
  }
`;
import { Row, Col } from "antd";
import Image from "next/image";
import Typography from "../Typography";
import Button from "../PButton";
import Link from "next/link";
import { Container } from "./styled";
import PropTypes from "prop-types";

const ImageCardComponent = ({ image, title, description, link, buttonText }) => {
  return (
    <Container>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="card-image-wrap" >
            <Image
              src={image.src}
              alt={image.alt}
              layout="responsive"
              width={image.width || 100}
              height={image.height || 100}
              objectFit="cover"
              quality={image.quality || 50}
              className={"card-image"}
            />
          </div>
        </Col>
        <Col xs={24}>
          <Typography
            textAlign="left"
            color="darkGray2"
            fontWeight="bold"
            fontSize="1.6rem"
            fontFamily="Roboto Bold"
            style={{ letterSpacing: "0.1rem" }}
            className="card-title"
          >
            {title}
          </Typography>
          <Typography
            fontWeight="normal"
            color="black"
            fontSize="1.15rem"
            textAlign="left"
            style={{ letterSpacing: "0.1rem" }}
            className="card-description"
            fontFamily="Roboto Medium"
          >
            {description}
          </Typography>
          {link &&
            <Link href={link}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
              >
                {buttonText}
              </Button>
            </Link>
          }
        </Col>
      </Row>
    </Container>
  );
};

ImageCardComponent.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
};

export default ImageCardComponent;
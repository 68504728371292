import { useEffect, useState } from "react";
import pNotification from "../components/PNotification";

import { eventFormSubmission } from "../services/analytics/ga4CustomEvents";
import { validateEmail } from "../utils/emailValidation";
import { getQueryFromStorage } from "./useQueryCatcher";
import useContact from "./useContact";

const useForm = ({ defaultFormValues }) => {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [emailErr, setEmailErr] = useState(false);
  const {
    loading,
    error: formError,
    data: formData,
    sendContact,
  } = useContact();

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const sendPixelInfo = async (data) => {
    const response1 = await fetch("/api/crm/pint-conversion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ ...data, event_time: Date.now() }),
    });
    const response2 = await fetch("/api/crm/fb-conversion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ ...data, event_time: Date.now() }),
    });
  };

  const handleSubmit = () => {
    if (
      !emailErr &&
      (formValues.first_name !== "" || formValues.firstname !== "") &&
      validateEmail(formValues.email) &&
      (data?.confidence?.score > 0.25 || error)
    ) {
      sendPixelInfo(formValues);
      sendContact({
        ...formValues,
        query: getQueryFromStorage(),
      });
    } else {
      pNotification({
        type: "error",
        message: "Failed to submit!",
        description: "Please include Name, Email, and Phone!",
      });
    }
  };

  useEffect(() => {
    if (formData) {
      let url = typeof window !== "undefined" ? window.location.href : "null";
      eventFormSubmission(url, `lead`);
      setFormValues(defaultFormValues);
    }
  }, [formData]);

  return {
    formValues,
    loading,
    emailErr,
    formError,
    formData,
    handleChange,
    handleSubmit,
    setEmailErr,
  };
};

export default useForm;

import { useState } from "react";
import { useEffect } from "react";
import Typography from "../../../../../components/Typography";
import LargeForm from "./LargeForm";
import SmallForm from "./SmallForm";
import { ScheduleFormContainer } from "./styled";

export default function ScheduleForm({ variant, title, padding }) {
  const variants = ["small", "large"];
  variant = variants.includes(variant) ? variant : variants[0];
  const [showSmall, setShowSmall] = useState(true);

  useEffect(() => {
    setShowSmall(variant === "small");
  }, [variant]);

  return (
    <ScheduleFormContainer padding={padding}>
      {title && (
        <Typography
          color="white"
          fontWeight="bold"
          fontSize="1.4rem"
          textAlign="center"
        >
          {title}
        </Typography>
      )}
      {showSmall && <SmallForm />}
      {!showSmall && <LargeForm />}
    </ScheduleFormContainer>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(241, 240, 239);
  padding-top: 45px;
  padding-bottom: 20px;
  padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop - 15}px;
  padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop - 15}px;

  @media only screen and (max-width: 768px) {
    padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.ipad}px;
    padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.ipad}px;
  }
  @media only screen and (max-width: 560px) {
    padding-top: 27px;
  }
`;

export const SectionTitleWrap = styled.div`
  @media only screen and (max-width: 560px) {
    display: flex;
    justify-content: center;
    align-items: center;
    & div {
      max-width: 286px;
      font-size: 20px;
    }
  }
`;

export const StepsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding-top: 56px;
  gap: 2em;

  @media only screen and (max-width:1000px) {
    flex-flow: row wrap;
  }

  @media only screen and (max-width: 768px) {
    justify-content: space-evenly;
    flex-flow: row wrap;
    gap: 0px;
  }
  @media only screen and (max-width: 560px) {
    padding-top: 11px;
  }
`;

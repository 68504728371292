import Step from "../../Home/StepsSection/Step";
import dataArray from "./_authority.json";
import { ModifiedStepsWrap } from "./styled";

const StepsSection = () => {

  return (
    <ModifiedStepsWrap>
      {dataArray.map((item, index) => (
        <Step
          key={item.id}
          iconImg={item.icon}
          title={item.title}
          summary={item.summary}
          textColor={"black"}
        />
      ))}
    </ModifiedStepsWrap>
  );
};

export default StepsSection;

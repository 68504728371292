import Iframe from "react-iframe";
import {
  Container,
  IframeContainer,
  VirtualDescription,
  FinancingContainer,
  ImageContainer,
  FinanceDescription,
} from "./styled";
import { Row, Col } from "antd";
import Typography from "../../../components/Typography";
import PButton from "../../../components/PButton";
import Link from "next/link";
import useMobile from "../../../hooks/useMobile";
import Image from "next/image";

const VirtualSection = ({ iframe, description, buttonProps }) => {
  const isMobile = useMobile();
  return (
    <>
      <FinancingContainer>
        <Row align="middle" justify="center" >
          <Col xs={24} md={12}>
            <ImageContainer>
              <Image
                src={"/images/kitchen-home/financing.jpg"}
                quality={40}
                width={1060}
                height={686}
                layout={"responsive"}
              />
            </ImageContainer>
          </Col>
          <Col xs={24} md={12}>
            <FinanceDescription>
              <Row align="top">
                <Col xs={24} >
                  <Typography
                    color={"#762738"}
                    fontWeight="bold"
                    fontSize="2.25rem"
                    textAlign={isMobile ? "center" : "left"}
                    fontFamily="Lora"
                    style={{ letterSpacing: "0.1rem" }}
                    id="virtual-tour"
                  >
                    Financing Options Available
                  </Typography>
                </Col>
                <Col xs={24}>
                  <Typography
                    color={"black"}
                    fontWeight="normal"
                    fontSize="1rem"
                    textAlign="left"
                    fontFamily="Roboto medium"
                  >
                    We are excited to now offer financing options through our partners at GreenSky®. The GreenSky® Loan Program’s focus is simple-to help you create the home of your dreams. From deferred interest promotions to affordable budget-minded options, our loans are an easy and convenient way to pay for any Classy Closets designed space. Offers available at select Classy Closet locations.*
                  </Typography>

                </Col>
                <Col xs={24}>
                  <Link href="/landing/financing">
                    <a>
                      <PButton
                        ptype="default"
                        pname="FINANCING OPTIONS"
                        pbottom="15px"
                        ptop="15px"
                        pleft="15px"
                        pright="15px"
                      />
                    </a>
                  </Link>
                </Col>
              </Row>
            </FinanceDescription>
          </Col>
        </Row>
      </FinancingContainer>
      <Container>
        {isMobile && (<Typography
          color={"#762738"}
          fontWeight="bold"
          fontSize="2.25rem"
          textAlign={isMobile ? "center" : "left"}
          fontFamily="Lora"
          style={{ letterSpacing: "0.1rem" }}
          id="virtual-tour"
        >
          Take a Virtual Tour
        </Typography>)}
        <Row align="middle" justify="center" >

          <Col order={isMobile ? 2 : 1} xs={24} md={12}>
            <VirtualDescription>
              <Row align="bottom">
                <Col xs={0} md={24} >
                  <Typography
                    color={"#762738"}
                    fontWeight="bold"
                    fontSize="2.25rem"
                    textAlign={isMobile ? "center" : "left"}
                    fontFamily="Lora"
                    style={{ letterSpacing: "0.1rem" }}
                    id="virtual-tour"
                  >
                    Take a Virtual Tour
                  </Typography>
                </Col>
                <Col xs={24}>
                  <Typography
                    color={"black"}
                    fontWeight="normal"
                    fontSize="1rem"
                    textAlign="left"
                    fontFamily="Roboto medium"
                  >
                    Immerse yourself in our kitchen collection like never before with our immersive 360 virtual tour. Explore every corner of the kitchen, from the countertops to the cabinets, and get a complete understanding of its layout and functionality. Whether you're envisioning a spacious chef's kitchen or a cozy family gathering space, our 360-degree view provides a fully immersive and interactive experience, allowing you to visualize the kitchen as if you were standing in it. Don't just imagine your dream kitchen, experience it with our 360-degree view!
                  </Typography>

                </Col>
                <Col xs={24}>
                  <Link href="/360-page">
                    <a>
                      <PButton
                        ptype="default"
                        pname="VIRTUAL TOURS"
                        pbottom="15px"
                        ptop="15px"
                        pleft="15px"
                        pright="15px"
                      />
                    </a>
                  </Link>
                </Col>
              </Row>
            </VirtualDescription>
          </Col>
          <Col order={isMobile ? 1 : 2} xs={24} md={12}>
            <IframeContainer>
              <Iframe
                url={"https://momento360.com/e/u/623f7c9c249a48f28ae730df66596b3e"}
                width="100%"
                height="100%"
                id="virtual-tour-iframe"
                className="virtual-tour-iframe"
                // display="initial"
                // position="absolute"
                allowFullScreen
                frameBorder={0}
              />
            </IframeContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VirtualSection;
import React from "react";
import { Row, Col } from "antd";
import Loading from "../../../../../../components/Loading";
import {
  Container,
  ElementWrap,
  InlineElements,
  SubmitButton,
  PInput,
  PTextArea,
} from "../styled";
import { defaultFormValues } from "../../../../../Franchise/FranchiseForm/_form";
import useForm from "../../../../../../hooks/useForm";
import Form from "../../../../../Forms/Consultation";

const LargeForm = () => {
  const { formValues, loading, setEmailErr, handleChange, handleSubmit } =
    useForm({ defaultFormValues });

  return (
    <Container>
      <Form variant={"large"} />
    </Container>
  );
};

export default LargeForm;

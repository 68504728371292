import styled, { css } from "styled-components";
import _PInput from "../../../../../components/PInput";
import PButton from "../../../../../components/PButton";
import _PTextArea from "../../../../../components/PTextArea";

export const ScheduleFormContainer = styled.div`
  background-color: ${(props) => props.theme.colors.darkGray2};
  padding: ${({ padding }) => padding || "20px 8px 12px 8px"};
`;

export const Container = styled.div`
  max-width: 640px;
  margin: 0px auto;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const ElementWrap = styled.div`
  padding-bottom: ${({ theme, pbottom }) =>
    pbottom ? pbottom : theme.spacings.spacing_xs};
  padding-right: ${({ theme, pright }) =>
    pright ? pright : theme.spacings.spacing_xs};
  padding-left: ${({ theme, pleft }) =>
    pleft ? pleft : theme.spacings.spacing_xs};
  ${({ ptop }) => ptop && `padding-top: ${ptop}`};
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  justify-content: center;
`;

export const InlineElements = styled.div`
  display: flex;
`;

export const SubmitButton = styled(PButton)`
  padding: 20px;
  width: 100%;
  border-radius: 0px;
  border: 0px;

  ${({ theme, variant }) => css`
    border: 2px solid ${variant ? theme.colors.white : theme.colors.darkGray2};
    background-color: ${variant ? theme.colors.darkGray2 : theme.colors.white};
    & span {
      color: ${variant ? theme.colors.white : theme.colors.darkGray2};
    }
  `}

  & span {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 18px;
  }
`;

export const PInput = styled(_PInput)`
  box-shadow: none;
  border: none;
  padding: 15px;
`;

export const PTextArea = styled(_PTextArea)`
  box-shadow: none;
  border: none;
  padding: 15px;
`;

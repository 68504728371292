import Styled from 'styled-components';
const TEXT_PADDING_SIDE = "2rem";
const IMAGE_PADDING = "1rem";

export const Container = Styled.div`
  width: 100%;
  height: 100%;

  .card-image-wrap {
    position: relative;
    padding: ${IMAGE_PADDING};
    @media only screen and (max-width: 768px) {
      padding: 0;
  }
  }

  .card-description, .card-title {
    padding: 0 ${TEXT_PADDING_SIDE};
  }
`;
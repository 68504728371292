import styled from "styled-components";
import { StepsWrap } from "../../Home/StepsSection/styled";

export const ModifiedStepsWrap = styled(StepsWrap)`
  justify-content: space-around;
  flex-wrap: nowrap;
  font-family: "Roboto Regular" !important;
  h2 {
    padding-top: .5rem;
    height: unset;
    font-family: "Roboto Bold";
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    & > div {
      justify-content: center;
      padding: 0 1.5rem 0 0;
    }
  }
  @media only screen and (min-width: 1200px) {
    justify-content: center;
    column-gap: 3%;
  }
`;

import dynamic from 'next/dynamic';
const AppLayout = dynamic(() => import('../../components/AppLayout'));
const Spacer = dynamic(() => import('../../components/Content/Spacer'));
const Banner = dynamic(() => import('./UpdatedHomePage'));
const StepsSection = dynamic(() => import('../Home/StepsSection'));
const GetStarted = dynamic(() => import('./GetStarted'));
const ProductsAndServices = dynamic(() => import('./ProductsAndServices'));
const ReviewsSection = dynamic(() => import('./Reviews'));
const MetaCommon = dynamic(() => import('../../components/Meta/MetaCommon'));
import ContactForm from '../CabinetsPage/About/Schedule/ScheduleForm';
import AuthoritySection from './EEAT';
import ImageCardComponent from '@classymonorepo/classy-closets/components/ImageCard';
import cardData from './_cardImage.json';
import { Col, Row } from 'antd';
import Typography from '../../components/Typography';
import VirtualSection from './VirtualTour';

export default function ClosetsHomePage() {
  return (
    <>
      <MetaCommon metaDataKey="home" />
      <AppLayout>
        <Banner />
        <ContactForm
          variant="large"
          title="Schedule Your Free In-Home Consultation!"
          formId="top-home-page"
        />
        <AuthoritySection />
        <Spacer size="spacing_big" />
        {/* <AboutClassyClosets /> */}

        <Spacer size="spacing_m" />
        <ProductsAndServices />
        <Typography
          style={{ padding: "1.5rem" }}
          id="why-choose-classy-closets"
          textAlign="center"
          fontSize="2rem"
          fontFamily="Lora"
          fontWeight="bold"
          color="#762738"
        >
          Why Choose Classy Kitchen
        </Typography>
        <Row justify="center" gutter={[16, 16]}>
          {cardData?.map((item) => {
            return (
              <Col xs={24} md={7}>
                <ImageCardComponent
                  key={item.id}
                  title={item.title}
                  image={item.image}
                  description={item.description}
                  title={item.title}
                />
              </Col>
            );
          })
          }
        </Row>
        {/* <ClassyDesignFeatures
          image="/images/closets-home/features.png"
          titleDark
        /> */}
        <StepsSection />
        <ReviewsSection
          desktopImage={"/images/kitchen-home/desktop-reviews.jpg"}
          mobileImage={"/images/kitchen-home/desktop-reviews.jpg"}
        />
        <VirtualSection />
        <GetStarted formId={"get-started-home-page"} />
      </AppLayout>
    </>
  );
}

import { useState } from "react";
import axios from "axios";

export default function useContact() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);

  function sendContact(data) {
    setLoading(true);
    setError(undefined);
    setData(undefined);
    axios
      .post("/api/contact", {
        ...data,
      })
      .then(res => setData(res.data))
      .catch(() => setError("Failed to send contact info."))
      .finally(() => setLoading(false));
  }

  return {
    loading,
    error,
    data,
    sendContact,
  };
}
